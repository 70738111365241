import { WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { t } from 'i18next'
import { FC } from 'react'
import { useStore } from '../../hooks/use-store'
import { useTrackingEvents } from '../../hooks/use-tracking-events'
import { NoResultsIllustration } from '../../icons/no-results-illustration'
import { Button } from '../button/button'
import { TextReveal } from '../text-reveal/text-reveal'
import styles from './no-results-page.module.css'

export const NoResultsPage: FC<WithClass> = ({ className }) => {
	const { jobsCount, resetState, links } = useStore(({ resetState, jobsCount, links }) => ({
		resetState,
		jobsCount,
		links,
	}))

	const { trackOnclickcta } = useTrackingEvents()

	return (
		<motion.div className={clsx(styles.container, className)}>
			<TextReveal
				className={styles.headline}
				sentences={[{ text: t('noResultsPage.headline') }]}
				duration={1}
				autoSize={false}
			/>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{
					opacity: 1,
				}}
				transition={{ delay: 1 }}
				className={styles.content}
			>
				<h3 className={clsx(styles.subline, 'medium')}>{t('noResultsPage.subline')}</h3>

				<p className={styles.body}>{t('noResultsPage.body')}</p>

				<Button
					className={styles.button}
					type="secondary"
					dark
					href={links?.career}
					onClick={() => trackOnclickcta?.()}
					target="_blank"
					rel="noreferrer"
				>
					{t('noResultsPage.jobsButtonLabel', {
						count: jobsCount,
					})}
				</Button>
				<Button type="tertiary" dark className={styles.button} onClick={resetState}>
					{t('noResultsPage.resetButtonLabel')}
				</Button>
			</motion.div>
			<NoResultsIllustration
				initial={{ opacity: 0 }}
				animate={{
					opacity: 1,
				}}
				transition={{ delay: 1 }}
				className={styles.image}
			/>
		</motion.div>
	)
}
