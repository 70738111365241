import { useEffect, useRef, useState } from 'react'
import { TrackingEventsAsString, useStore } from './use-store'

type EventName = `track${Capitalize<keyof TrackingEventsAsString>}`

type TrackingEvents = Partial<Record<EventName, () => void>>

export const useTrackingEvents = (): TrackingEvents => {
	const { trackingEvents } = useStore(({ trackingEvents }) => ({
		trackingEvents,
	}))

	const isInitialized = useRef(false)
	const [evaluatedTrackingEvents, setEvaluatedTrackingEvents] = useState<TrackingEvents>()

	useEffect(() => {
		if (trackingEvents === undefined) {
			return
		}

		if (evaluatedTrackingEvents !== undefined) {
			return
		}

		isInitialized.current = true

		const mappedEvents: TrackingEvents = (
			Object.entries(trackingEvents) as [keyof TrackingEventsAsString, string][]
		).reduce((events, [name, event]) => {
			if (event === undefined) {
				return events
			}

			const eventName = `track${name.charAt(0).toUpperCase()}${name.slice(1)}` as EventName

			return {
				...events,
				[eventName]: () => setTimeout(event),
			}
		}, {})

		setEvaluatedTrackingEvents(mappedEvents)
	}, [evaluatedTrackingEvents, trackingEvents])

	return evaluatedTrackingEvents || {}
}
