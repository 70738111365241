import { WithClass } from '@decadia/shared/types/extend-default-props'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { t } from 'i18next'
import { FC } from 'react'
import { useStore } from '../../hooks/use-store'
import { Button } from '../button/button'
import { TextReveal } from '../text-reveal/text-reveal'
import styles from './error-page.module.css'
export const ErrorPage: FC<WithClass> = ({ className }) => {
	const { resetState, error } = useStore(({ resetState, error }) => ({
		resetState,
		error,
	}))

	return (
		<motion.div className={clsx(styles.container, className)}>
			<TextReveal
				className={styles.headline}
				sentences={[{ text: t('errorPage.headline', { context: error }) }]}
				duration={1}
				autoSize={false}
			/>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{
					opacity: 1,
				}}
				transition={{ delay: 1 }}
				className={styles.content}
			>
				<Button type="secondary" className={styles.button} onClick={resetState}>
					{t('errorPage.buttonLabel')}
				</Button>
			</motion.div>
		</motion.div>
	)
}
