import { WithClass } from '@decadia/shared/types/extend-default-props'
import { AnimatePresence } from 'framer-motion'
import { t } from 'i18next'
import { FC, useMemo } from 'react'
import { Step, useStore } from '../../hooks/use-store'
import { ErrorPage } from '../error-page/error-page'
import { NoResultsPage } from '../no-results-page/no-results-page'
import { TextReveal } from '../text-reveal/text-reveal'

export const Steps: FC<WithClass> = ({ className }) => {
	const { step, jobsCount } = useStore(({ step, jobsCount }) => ({
		step,
		jobsCount,
	}))

	const currentStep = useMemo(() => {
		switch (true) {
			case step === Step.Initial:
				return (
					<TextReveal
						layout="preserve-aspect"
						className={className}
						key={step}
						sentences={(
							t('steps.initial', { returnObjects: true }) as {
								text: string
								highlighted?: boolean
							}[]
						).map(({ text, highlighted }) => ({
							text,
							className: highlighted ? 'highlight-text' : undefined,
						}))}
						minSize={24}
					/>
				)

			case step === Step.Processing:
				return (
					<TextReveal
						layout="preserve-aspect"
						className={className}
						key={step}
						sentences={[{ text: t('steps.processing') }]}
					/>
				)

			case step === Step.Preselecting:
				return (
					<TextReveal
						layout="preserve-aspect"
						className={className}
						key={step}
						sentences={[{ text: t('steps.preselecting', { count: jobsCount }) }]}
					/>
				)

			case step === Step.Evaluating:
				return (
					<TextReveal
						layout="preserve-aspect"
						className={className}
						key={step}
						sentences={[{ text: t('steps.evaluating') }]}
					/>
				)

			case step === Step.Selecting:
				return (
					<TextReveal
						key={`headline-${step}`}
						layout="preserve-aspect"
						className={className}
						sentences={[{ text: t('steps.selecting') }]}
						maxSize={84}
					/>
				)

			case step === Step.NoResults:
				return <NoResultsPage className={className} />

			case step === Step.Error:
				return <ErrorPage className={className} />

			default:
				return null
		}
	}, [step, className, jobsCount])

	return <AnimatePresence mode="wait">{currentStep}</AnimatePresence>
}
